import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import ActionButton from './buttons/Button';
import MemberTable from './MemberTable';
import ShareDashboardModal from './ShareDashboardModal';

const Management = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { institutionID, institutionName } = location.state || {};

  const [membersData, setMembersData] = useState([]);
  const [invitationsData, setInvitationsData] = useState([]);
  const [inheritedMembersData, setInheritedMembersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isConsortium, setIsConsortium] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [invitationToRevoke, setInvitationToRevoke] = useState(null);

  let apiUrl;
  const environment = process.env.REACT_APP_ENV;
  switch (environment) {
    case 'LOCAL':
      apiUrl = process.env.REACT_APP_API_Local_URL;
      break;
    case 'DEV':
    case 'PRD':
      apiUrl = process.env.REACT_APP_API_URL;
      break;
    default:
      apiUrl = process.env.REACT_APP_API_URL;
  }

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const formatDateToUTC = (date) => {
    return new Date(date).toISOString();
  };

  const formatDateToLocale = (date) => {
    if (!date) return '';
    
    const dateObj = new Date(date);
    
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date:', date);
      return 'Invalid Date';
    }
  
    const localDate = new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000));
  
    return localDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetch current user data
        const userResponse = await fetch(`${apiUrl}/getuser`, {
          method: 'GET',
          credentials: 'include'
        });
        if (!userResponse.ok) {
          throw new Error('Failed to fetch user data');
        }
        const userData = await userResponse.json();
        setCurrentUser(userData);
  
        // Check if user is a manager or admin
        const isManager = await fetchManagerStatus();
        const isAuthorized = userData.isAdmin || isManager;
        setIsAuthorized(isAuthorized);
  
        if (isAuthorized) {
          await fetchInstitutionData();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [apiUrl, institutionID]);

  const fetchManagerStatus = async () => {
    try {
      const response = await fetch(`${apiUrl}/isManager?sid=${institutionID}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        return data.isManager;
      } else {
        console.error('Failed to fetch manager status');
        return false;
      }
    } catch (error) {
      console.error('Error fetching manager status:', error);
      return false;
    }
  };

  const fetchInstitutionData = async () => {
    try {
      if (!institutionID) throw new Error('Institution ID is missing');
      const membersResponse = await fetch(`${apiUrl}/getInstitutionMembers/${institutionID}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!membersResponse.ok) throw new Error('Failed to fetch institution members');
      const membersData = await membersResponse.json();

      const inheritedMembersResponse = await fetch(`${apiUrl}/getInheritedMembers/${institutionID}`, {
        method: 'GET',
        credentials: 'include'
      });

      let formattedInheritedMembers = [];
      if (inheritedMembersResponse.ok) {
        const inheritedMembersData = await inheritedMembersResponse.json();

        if (inheritedMembersData.message && inheritedMembersData.message.includes("consortium itself")) {
          setIsConsortium(true);
        } else if (Array.isArray(inheritedMembersData)) {
          formattedInheritedMembers = inheritedMembersData.map(member => ({
            'Family Name': member.family_name,
            'Given Name': member.given_name,
            'Email': member.email,
            'Title': member.job_title,
            'Manager': member.can_manage,
            'Consortium Name': member.consortium_name
          }));
        }
      } else {
        const errorData = await inheritedMembersResponse.json();
        throw new Error(errorData.error || 'Failed to fetch inherited members');
      }

      const invitationsResponse = await fetch(`${apiUrl}/getInstitutionAuthorizations/${institutionID}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!invitationsResponse.ok) throw new Error('Failed to fetch institution invitations');
      const invitationsData = await invitationsResponse.json();
      const filteredInvitations = invitationsData.filter(invitation => invitation.is_revoked !== 1);

      const formattedMembers = membersData.map(member => ({
        'Family Name': member.family_name,
        'Given Name': member.given_name,
        'Email': member.email,
        'Title': member.job_title,
        'Manager': member.can_manage,
        'user_id': member.user_id
      }));

      console.log('Raw invitations:', filteredInvitations);

      const formattedInvitations = filteredInvitations.map(invitation => {
        const formattedDate = invitation.requested_at ? formatDateToLocale(invitation.requested_at) : '';
        console.log('Original date:', invitation.requested_at, 'Formatted date:', formattedDate);
        return {
          'Family Name': invitation.family_name || '',
          'Given Name': invitation.given_name || '',
          'Email': invitation.email || '',
          'Title': invitation.job_title || '',
          'Invited': formattedDate
        };
      });

      console.log('Formatted invitations:', formattedInvitations);

      setMembersData(formattedMembers);
      setInheritedMembersData(formattedInheritedMembers);
      setInvitationsData(formattedInvitations);
    } catch (err) {
      setError(err.message);
    }
  };

  const handlePromoteToManager = async (member) => {
    try {
      const url = new URL(`${apiUrl}/promoteToManager`);
      url.searchParams.append('user_id_to_promote', member.user_id);
      url.searchParams.append('sid', institutionID);

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to promote user to manager');
      }

      console.log(`${member['Given Name']} ${member['Family Name']} promoted to manager successfully`);

    } catch (error) {
      console.error("Error promoting user to manager:", error);
    }
  };

  const handleDemoteManager = async (member) => {
    try {
      const url = new URL(`${apiUrl}/demoteManager`);
      url.searchParams.append('user_id_to_demote', member.user_id);
      url.searchParams.append('sid', institutionID);

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to demote user from manager');
      }

      console.log(`${member['Given Name']} ${member['Family Name']} demoted from manager successfully`);

    } catch (error) {
      console.error("Error demoting user from manager:", error);
    }
  };

  const handleSendInvitation = async (email, givenName, familyName, jobTitle) => {
    try {
      const response = await fetch(`${apiUrl}/grantAccess?inst_id=${institutionID}&email=${email}&inst_name=${institutionName}&given_name=${givenName}&family_name=${familyName}&job_title=${jobTitle}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to send invitation');
      }

      console.log(`Invitation sent successfully to ${email}`);

      const newInvitation = {
        'Family Name': familyName,
        'Given Name': givenName,
        'Email': email,
        'Title': jobTitle,
        'Invited': formatDateToUTC(new Date())
      };

      setInvitationsData(prevInvitations => {
        const updatedInvitations = [newInvitation, ...prevInvitations];
        return updatedInvitations.sort((a, b) =>
          new Date(b.Invited) - new Date(a.Invited)
        );
      });

      return true;
    } catch (error) {
      console.error("Error sending invitation:", error);
      throw error;
    }
  };

  const handleResendInvitation = async (row) => {
    try {
      if (!row || !row.Email) {
        console.error("Invalid row data:", row);
        throw new Error("Invalid row data");
      }
  
      // const response = await fetch(`${apiUrl}/resendInvite?email=${row.Email}&sid=${institutionID}`, {
      //   method: 'GET',
      //   credentials: 'include'
      // });
  
      // if (!response.ok) {
      //   throw new Error('Failed to resend invitation');
      // }
  
      console.log("Invitation resent successfully to:", row.Email);

      setInvitationsData(prevInvitations =>
        prevInvitations.map(invitation =>
          invitation.Email === row.Email
            ? { ...invitation, 'Invited': formatDateToUTC(new Date()) }
            : invitation
        )
      );

      return true;
    } catch (error) {
      console.error("Error resending invitation:", error);
      return false;
    }
  };

  const handleRemoveAccess = async (userId) => {
    try {
      const response = await fetch(`${apiUrl}/removeMemberAccess?user_id_to_remove=${userId}&sid=${institutionID}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to remove member access');
      }

      setMembersData(prevMembersData =>
        prevMembersData.filter(member => member.user_id !== userId)
      );

      console.log(`Member access removed successfully for user ID: ${userId}`);
    } catch (error) {
      console.error('Error removing member access:', error);
    }
  };

  const handleOpenConfirmDialog = (invitation) => {
    setInvitationToRevoke(invitation);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setInvitationToRevoke(null);
  };

  const handleConfirmRevoke = async () => {
    if (invitationToRevoke) {
      try {
        const response = await fetch(`${apiUrl}/revokeInvitation?email=${invitationToRevoke.Email}&sid=${institutionID}`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to revoke invitation');
        }

        console.log(`Invitation revoked successfully for: ${invitationToRevoke.Email}`);

        const newData = invitationsData.filter(row => row.Email !== invitationToRevoke.Email);
        setInvitationsData(newData);
        handleDeleteInvitation(newData, invitationToRevoke.Email);
      } catch (error) {
        console.error('Error revoking invitation:', error);
      }
    }
    handleCloseConfirmDialog();
  };

  const handleDeleteInvitation = (newData, deletedEmail) => {
    setInvitationsData(newData);
    setMembersData(prevMembersData =>
      prevMembersData.filter(member => member.Email !== deletedEmail)
    );
  };

  const handleDeleteMember = (newData, deletedEmail) => {
    setMembersData(newData);
    setInvitationsData(prevInvitationsData =>
      prevInvitationsData.filter(invitation => invitation.Email !== deletedEmail)
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!isAuthorized) {
    return <Typography color="error">You are not authorized to view this page.</Typography>;
  }

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <ActionButton onClick={() => navigate(-1)} text="← Back" />
        <ActionButton onClick={handleOpenModal} text="Send New Invites" />
      </Box>

      <h1 sx={{ mb: 2 }}>
        Access Management for {institutionName}
      </h1>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ mb: 4 }}>
          <MemberTable
            title="Has Access"
            data={membersData}
            columns={['Family Name', 'Given Name', 'Email', 'Title', 'Manager']}
            onSave={setMembersData}
            onDelete={handleDeleteMember}
            isInvitationTable={false}
            onPromoteToManager={handlePromoteToManager}
            onDemoteManager={handleDemoteManager}
            onRemoveAccess={handleRemoveAccess}
            apiUrl={apiUrl}
            institutionID={institutionID}
          />
        </Box>
        {!isConsortium && inheritedMembersData.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <MemberTable
              title="Inherited Access"
              data={inheritedMembersData}
              columns={['Consortium Name', 'Family Name', 'Given Name', 'Email', 'Title', 'Manager']}
              onSave={setInheritedMembersData}
              onDelete={setInheritedMembersData}
              isInvitationTable={false}
              isInheritedTable={true}
              apiUrl={apiUrl}
              institutionID={institutionID}
            />
          </Box>
        )}
        <Box sx={{ mt: 4, mb: 4 }}>
          <MemberTable
            title="Invitations"
            data={invitationsData}
            columns={['Family Name', 'Given Name', 'Email', 'Title', 'Invited']}
            onSave={setInvitationsData}
            onDelete={handleOpenConfirmDialog}
            onResendInvitation={handleResendInvitation}
            isInvitationTable={true}
            apiUrl={apiUrl}
            institutionID={institutionID}
          />
        </Box>
      </Box>

      <ShareDashboardModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSendInvitation={handleSendInvitation}
        instname={institutionName}
        apiUrl={apiUrl}
      />

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Invitation Revocation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to revoke the invitation for {invitationToRevoke?.Email}? This will permanently delete all invitation records associated with this email, and the action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleConfirmRevoke} autoFocus>
            Confirm Revoke
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Management;