import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const baseColumnWidths = {
  'Family Name': '20%',
  'Given Name': '20%',
  'Email': '20%',
  'Title': '15%',
  'Manager': '15%',
  'Actions': '20%',
};

const invitationColumnWidths = {
  'Family Name': '20%',
  'Given Name': '20%',
  'Email': '20%',
  'Title': '15%',
  'Invited': '15%',
  'Actions': '20%',
};

const inheritedColumnWidths = {
  'Family Name': '15%',
  'Given Name': '15%',
  'Email': '15%',
  'Title': '15%',
  'Manager': '10%',
  'Consortium Name': '20%',
  'Actions': '10%',
};

const MemberTable = ({
  title,
  data,
  columns,
  onSave,
  onDelete,
  onResendInvitation,
  onRemoveAccess,
  isInvitationTable,
  isInheritedTable,
  onPromoteToManager,
  onDemoteManager,
  institutionID,
  apiUrl
}) => {
  const [editedData, setEditedData] = useState(data);

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const getColumnWidths = () => {
    if (isInvitationTable) return invitationColumnWidths;
    if (isInheritedTable) return inheritedColumnWidths;
    return baseColumnWidths;
  };

  const columnWidths = getColumnWidths();

  const getColumnWidth = (column) => {
    return columnWidths[column] || 'auto';
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error('Invalid date:', dateString);
      return 'Invalid Date';
    }

    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const handleDelete = (index) => {
    const rowToDelete = editedData[index];
    onDelete(rowToDelete);
  };

  const handleResendInvitation = async (index) => {
    try {
      const row = editedData[index];
      if (onResendInvitation) {
        const success = await onResendInvitation(row);
        if (success) {
          // Create a new row with updated 'Invited' date in ISO format
          const newRow = {
            ...row,
            'Invited': new Date().toISOString()
          };
          // Add the new row at the beginning of the data
          const newData = [newRow, ...editedData];
          setEditedData(newData);
          if (onSave) {
            onSave(newData);
          }
        }
      }
    } catch (error) {
      console.error("Error resending invitation:", error);
      // TODO: show an error message to the user
    }
  };

  const handleRemoveAccess = async (userId) => {
    try {
      await onRemoveAccess(userId);

      // Remove the user from the local state
      const newData = editedData.filter(member => member.user_id !== userId);
      setEditedData(newData);
      onSave(newData);

      console.log(`Member access removed successfully for user ID: ${userId}`);
    } catch (error) {
      console.error('Error removing member access:', error);
      // TODO: Show an error message to the user
    }
  };


  const handleManagerToggle = async (index) => {
    if (isInheritedTable) return; // Disable toggle for inherited users
    const newData = [...editedData];
    const newManagerStatus = !newData[index]['Manager'];
    newData[index]['Manager'] = newManagerStatus;
    setEditedData(newData);

    if (newManagerStatus) {
      // Call onPromoteToManager when toggling from false to true
      await onPromoteToManager(newData[index]);
    } else {
      // Call onDemoteManager when toggling from true to false
      await onDemoteManager(newData[index]);
    }

    onSave(newData);
  };

  return (
    <Box sx={{ mb: 4, bgcolor: 'transparent', borderRadius: 1, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ color: '#b31b1b' }}>{title}</h2>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column} sx={{
                  width: getColumnWidth(column),
                  fontWeight: 'bold',
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  ...(column === 'Manager' && { paddingLeft: '12px' })
                }}>
                  {column}
                </TableCell>
              ))}
              {!isInheritedTable && (
                <TableCell sx={{ width: columnWidths['Actions'], fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    sx={{
                      width: getColumnWidth(column),
                      borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
                      ...(column === 'Manager' && { padding: '6px 16px 6px 12px' })
                    }}
                  >
                    {column === 'Email' ? (
                      <a href={`mailto:${row[column]}`} style={{ color: 'blue', textDecoration: 'none' }}>
                        {row[column]}
                      </a>
                    ) : column === 'Manager' ? (
                      isInheritedTable || isInvitationTable ? (
                        // For inherited users, show a non-interactive icon              
                        row[column] ? <CheckBoxIcon color="disabled" /> : <CheckBoxOutlineBlankIcon color="disabled" />
                      ) : (
                        <Tooltip title={row[column]
                          ? `Demote ${row['Given Name']} ${row['Family Name']} from manager role. They will lose the ability to manage users for this institution.`
                          : `Promote ${row['Given Name']} ${row['Family Name']} to manager role. They will be able to manage users for this institution.`}>
                          <IconButton onClick={() => handleManagerToggle(index)} size="small">
                            {row[column] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                          </IconButton>
                        </Tooltip>
                      )
                    ) : column === 'Invited' ? (
                      formatDate(row[column])
                    ) : (
                      row[column]
                    )}
                  </TableCell>
                ))}
                {!isInheritedTable && (
                  <TableCell sx={{ width: columnWidths['Actions'], borderBottom: '1px solid rgba(224, 224, 224, 0.5)' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {isInvitationTable ? (
                        <>
                          <Tooltip title={`Resend the invitation email to ${row['Email']}.`}>
                            <Button
                              onClick={() => handleResendInvitation(index)}
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: '0.7rem',
                                padding: '1px 6px',
                                minWidth: 'auto',
                                textTransform: 'none'
                              }}
                            >
                              Resend
                            </Button>
                          </Tooltip>
                          <Tooltip title={`Revoke the invitation for ${row['Given Name']} ${row['Family Name']} (${row['Email']}).`}>
                            <Button
                              onClick={() => handleDelete(index)}
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: '0.7rem',
                                padding: '1px 6px',
                                minWidth: 'auto',
                                textTransform: 'none'
                              }}
                            >
                              Revoke
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title={`Remove ${row['Given Name']} ${row['Family Name']}'s (${row['Email']}) access to this institution. They will no longer be able to view or interact with this institution's data.`}>
                          <Button
                            onClick={() => handleRemoveAccess(row.user_id)}
                            variant="outlined"
                            size="small"
                            sx={{
                              fontSize: '0.7rem',
                              padding: '1px 6px',
                              minWidth: 'auto',
                              textTransform: 'none'
                            }}
                          >
                            Remove Access
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default MemberTable;
