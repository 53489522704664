import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import Selection from './Selection';
import Report from "./reportCharts";
import Management from "./Management";
import HelmetConfig from "./HelmetConfig";
import RedirectLogin from "./Route/login";

function App() {
    return (
        <div>
            <HelmetConfig />
            <Router>
                <Routes>
                    <Route path="/" element={<RedirectLogin/>} />
                    <Route path="membershipreports/" element={<Navigate to="selection" />} />
                    <Route path="membershipreports/selection" element={<Selection />} />
                    <Route path="membershipreports/report" element={<Report/>} />
                    <Route path="membershipreports/management" element={<Management/>} />
                </Routes>
            </Router>
            {/*<IndeterminateCheckbox/>*/}
        </div>

    );
}

export default App;